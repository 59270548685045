import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Environment and Climate Change"
const PAGE_NAME = "engagement"
const EnvironmentPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-6">
		  
            <p>
              This program aims to promote youth involvement in
              environmental issues. Climate change, REDD+ program, environment
              protection, nature conservation and environmental education.
            </p>
			
			<p>
				This includes projects such as:
			</p>
            <ul>
			  <li>
				A project to plant a Million Trees between now and the end of 2021 (agroforestry and orchards). 
			  </li>
			  <li>
				Various projects of community capacity enhancement and resilience to the impacts of climate change in the Lake Kivu area.
			  </li>
			</ul>
          </div>
          <div className="col-sm-6">
            <Image imageKey={"image58"} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default EnvironmentPage
